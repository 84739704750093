import React from 'react';
import {
  arrayOf,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  Table,
  DateCell,
  OrderNumberCell,
  StatusCell,
  ViewDetailsCell,
  ServiceTypeCell,
} from '../../../shared/components';
import { relatedJobType } from '../../../shared/types';
import styles from './styles';

const title = 'Related Orders';
const tableConfig = [
  {
    id: 'service type',
    label: 'Type',
    sortFieldPath: 'relatedJobs.serviceType',
    renderValue: job => <ServiceTypeCell job={job} />,
  },
  {
    id: 'orderNumber',
    label: 'Order',
    sortFieldPath: 'relatedJobs.orderNumber',
    renderValue: job => <OrderNumberCell job={job} type="relatedJobs" />,
  },
  {
    id: 'orderDate',
    label: 'Order Placed',
    sortFieldPath: 'relatedJobs.orderDate',
    renderValue: job => <DateCell job={job} />,
  },
  {
    id: 'status',
    label: 'Job Status',
    sortFieldPath: 'relatedJobs.status',
    renderValue: job => <StatusCell job={job} />,
  },
  {
    id: 'goToOrderDetails',
    label: '',
    renderValue: job => <ViewDetailsCell job={job} />,
  },
];

const RelatedJobs = ({ relatedJobs }) => (
  <div>
    <Table
      title={title}
      tableConfig={tableConfig}
      data={relatedJobs}
      rowsShown={5}
      initialOrderDirection="desc"
      initialOrderKey="relatedJobs.serviceType"
    />
  </div>
);

RelatedJobs.propTypes = {
  relatedJobs: arrayOf(relatedJobType),
};

RelatedJobs.defaultProps = {
  relatedJobs: [],
};

export default withStyles(styles)(RelatedJobs);

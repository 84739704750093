import React from 'react';
import {
  object,
} from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { customerType } from '../../../shared/types';
import { phoneFormatter } from '../../../shared/utilities';

import Card from '../Card';

const styles = theme => ({
  customerName: {
    fontWeight: theme.text.boldWeight,
  },
  numberContainer: {
    marginTop: theme.spacing(1),
  },
  numberHeader: {
    fontSize: '18px',
    fontWeight: theme.text.boldWeight,
  },
  link: {
    color: theme.palette.primary.link,
    textDecoration: 'none',
  },
});

const renderPhoneNumbers = (phoneNumbers, classes) => {
  if (!phoneNumbers.length) return null;

  return phoneNumbers
    .filter(({ number = '' }) => {
      if (!number || !number.length) return false;

      return number.length > 3;
    })
    .map(({ number }, index) => {
      const isPrimary = index === 0;
      const key = isPrimary ? `primary-${number}` : `additional-${number}`;
      const label = `${isPrimary ? 'Primary' : 'Additonal'} Number`;

      return (
        <div key={key} className={classes.numberContainer}>
          <Typography className={classes.numberHeader}>
            {label}
          </Typography>
          <Typography variant="body1" className={classes.link}>
            {phoneFormatter(number)}
          </Typography>
        </div>
      );
    });
};

const ContactInformation = ({
  customer: {
    firstName,
    lastName,
    email,
    phones = [],
  } = {},
  classes,
}) => (
  <Card title="Contact Information">
    <Typography variant="h5" className={classes.customerName}>
      {firstName} {lastName}
    </Typography>
    <Typography variant="body1">
      <a href={'mailto:' + email} className={classes.link}>{email}</a>
    </Typography>
    {renderPhoneNumbers(phones, classes)}
  </Card>
);

ContactInformation.propTypes = {
  customer: customerType.isRequired,
  classes: object.isRequired,
};

export default withStyles(styles)(ContactInformation);

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { string } from 'prop-types';
import { Loader } from '../../../../shared/components';
import { GET_PRICING_AGREEMENT } from '../../../../apollo/queries';
import { DEADPOOL_SPEC_ID } from '../../../../constants';

function MinimumPayout({ id }) {
  return (
    <Query
      query={GET_PRICING_AGREEMENT}
      variables={{ id }}
    >
      {({ loading, data }) => {
        if (loading) {
          return (
            <div>
              <Loader />
            </div>
          );
        }

        const {
          specSheets,
        } = data.getPricingAgreement;
        const specsArray = specSheets.filter(spec => spec.minimumCost > 1);

        if (specsArray.length < 1) return null;

        const minimumPayout = specsArray
          // If we don't ignore deadpool then minimumcost will be wrong!
          .filter(spec => spec.specId !== DEADPOOL_SPEC_ID)
          .map(spec => (spec.minimumCost))
          .reduce((minimumCost, cost) => minimumCost + cost);

        return (
          <div>
            * minimun payout is ${minimumPayout.toFixed(2)}
          </div>
        );
      }}
    </Query>
  );
}

MinimumPayout.propTypes = {
  id: string.isRequired,
};

export default MinimumPayout;

import React from 'react';
import classNames from 'classnames';
import {
  bool,
  object,
} from 'prop-types';
import startCase from 'lodash/startCase';
import withStyles from '@material-ui/core/styles/withStyles';

import { jobType } from '../../types';

const styles = theme => ({
  text: {
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: theme.text.boldWeight,
  },
  completed: {
    color: theme.palette.primary.green,
  },
});

function StatusCell({ job = {}, isBold, classes }) {
  const { status } = job;
  const isCompleted = status ? status.toLowerCase() === 'completed' : '';
  const textStyles = classNames(
    classes.text,
    {
      [classes.bold]: isBold,
      [classes.completed]: isCompleted,
    },
  );

  return (
    <div className={textStyles}>
      {startCase(status)}
    </div>
  );
}

StatusCell.propTypes = {
  job: jobType.isRequired,
  isBold: bool,
  classes: object.isRequired,
};

StatusCell.defaultProps = {
  isBold: false,
};

export default withStyles(styles)(StatusCell);

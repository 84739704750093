import React from 'react';
import get from 'lodash/get';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import {
  bool,
  object,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import WarningIcon from '@material-ui/icons/Warning';
import homeDepotIcon from '../../../assets/images/home-depot-logo-sq.png';
import blindsComIcon from '../../../assets/images/blinds-com-logo-sq.png';
import { jobType } from '../../types';
import { SET_JOB_HAS_BEEN_READ } from '../../../apollo/mutations';
import { mutationArgWrapper } from '../../utilities';

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '23px',
    height: '23px',
    marginRight: theme.spacing(1),
  },
  warning: {
    width: '18px',
    height: '18px',
    marginLeft: theme.spacing(1),
    color: 'orange',
  },
  orderNumber: {
    textDecoration: 'none',
    color: theme.palette.primary.link,
  },
  bold: {
    fontWeight: theme.text.boldWeight,
  },
});

function getSiteIcon(siteCode) {
  if (siteCode === 'THD') return homeDepotIcon;

  if (siteCode === 'BDC') return blindsComIcon;

  return null;
}

function UnscheduledOrderNumberCell({ job, isBold, classes }) {
  const orderNumber = get(job, 'order.orderNumber');
  const overSized = !!get(job, 'aggregatedAttributes.oversizedVerticalFeet');
  const siteCode = get(job, 'siteCode');
  const src = getSiteIcon(siteCode);
  const jobDetailsUrl = `/job/${job.id}`;
  const orderNumberStyles = classNames(
    classes.orderNumber,
    { [classes.bold]: isBold },
  );

  const [setJobHasBeenRead] = useMutation(SET_JOB_HAS_BEEN_READ, {
    errorPolicy: 'none',
  });

  const setJobHasBeenReadWrappedMutation = mutationArgWrapper('setJobHasBeenRead', setJobHasBeenRead);

  return (
    <div className={classes.container}>
      <img src={src} alt="site-source" className={classes.icon} />
      <Link
        to={jobDetailsUrl}
        onClick={() => setJobHasBeenReadWrappedMutation({ status: true, jobIds: [job.id] })}
        className={orderNumberStyles}
      >
        {orderNumber}
      </Link>
      {overSized && <WarningIcon className={classes.warning} />}
    </div>
  );
}

UnscheduledOrderNumberCell.propTypes = {
  job: jobType.isRequired,
  isBold: bool,
  classes: object.isRequired,
};

UnscheduledOrderNumberCell.defaultProps = {
  isBold: false,
};

export default withStyles(styles)(UnscheduledOrderNumberCell);

import React, { useState } from 'react';
import {
  string,
  arrayOf,
} from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMutation } from '@apollo/client';
import {
  Button,
  DialogContentText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';

import Person from '@material-ui/icons/Person';
import { ASSIGN_TECHNICIANS_TO_APPOINTMENT } from '../../../apollo/mutations';

import LoadingDialog from '../../../shared/components/LoadingDialog';
import GreenCheckbox from '../../../shared/components/GreenCheckbox';
import { LOGIN_USER } from '../../../constants';
import Card from '../Card';
import styles from './styles';
import AssignedTechnicians from './AssignedTechnicians';

const disabledStatuses = [
  'pending-schedule',
  'completed',
];

const useStyles = makeStyles(styles);

const TechAssignment = ({
  appointmentId,
  jobId,
  availableTechnicians = [],
  assignedTechnicians = [],
  jobStatus = '',
}) => {
  // Hooks
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [assignTechniciansToAppointment, { error, loading, reset }] = useMutation(ASSIGN_TECHNICIANS_TO_APPOINTMENT, {
    errorPolicy: 'none',
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [newlyAssignedTechnicians, setNewlyAssignedTechnicians] = useState([...assignedTechnicians]);

  // Derived State
  const isDialogConfirmButtonDisabled = newlyAssignedTechnicians.every(newAssignedTechnician => assignedTechnicians.includes(newAssignedTechnician)) && newlyAssignedTechnicians.length === assignedTechnicians.length;
  const assignOrEditTechniciansButtonText = assignedTechnicians.length === 0 ? 'Assign Technicians' : 'Edit Technicians';
  const availableTechniciansFiltered = [...availableTechnicians].sort().filter(availableTechnician => availableTechnician.toLowerCase().startsWith(searchFilter.toLowerCase()) || setSearchFilter === '');
  const isAssignOrEditTechniciansButtonDisabled = disabledStatuses.includes(jobStatus.toLowerCase());
  const isJobPendingSchedule = jobStatus === 'pending-schedule';

  // Functions
  const openAssignTechniciansDialog = () => {
    setNewlyAssignedTechnicians([...assignedTechnicians]);
    setIsDialogOpen(true);
  };

  const closeAssignTechniciansDialog = () => {
    setNewlyAssignedTechnicians([]);
    setSearchFilter('');
    reset();
    setIsDialogOpen(false);
  };

  const toggleTechnicianAssignment = (isChecked, technician) => {
    if (isChecked) {
      setNewlyAssignedTechnicians(previousCheckedTechnicians => [
        ...previousCheckedTechnicians,
        technician,
      ]);
    } else {
      setNewlyAssignedTechnicians(previousCheckedTechnicians => previousCheckedTechnicians.filter(assignedTechnician => assignedTechnician !== technician));
    }
  };

  const submitAssignedTechnicians = () => {
    const status = newlyAssignedTechnicians.length ? 'scheduled' : 'pending-assignment';

    assignTechniciansToAppointment({
      variables: {
        assignTechniciansInput: {
          appointmentId,
          technicians: newlyAssignedTechnicians,
        },
        setJobStatusInput: {
          jobId,
          status,
          updatedBy: LOGIN_USER(),
          forceStatusUpdate: !newlyAssignedTechnicians.length,
        },
      },
      onCompleted: () => {
        closeAssignTechniciansDialog();
      },
    });
  };

  return (
    <Card title="Technician Assignment">
      {isJobPendingSchedule &&
        <Typography className={classes.selectArrivalTimeFirst}>Please select arrival time first.</Typography>
      }
      <AssignedTechnicians className={classes.assignedTechnicians} technicians={assignedTechnicians} />
      <LoadingDialog
        isLoading={loading}
        error={error}
        isOpen={isDialogOpen}
        isDialogSubmitButtonDisabled={isDialogConfirmButtonDisabled}
        dialogTitleText="Please assign technicians"
        dialogSubmitButtonText="Confirm Change"
        dialogLoadingText="Confirming Change"
        onErrorTryAgain={() => reset()}
        onDialogClose={closeAssignTechniciansDialog}
        onDialogSubmit={submitAssignedTechnicians}
        fullScreen={fullScreen}
        dialogContent={(
          <>
            <DialogContentText>
              You may select more than one technician.
              <div className={classes.selectedCountContainer}>
                <Person /> {`(${newlyAssignedTechnicians.length}) Selected`}
              </div>
            </DialogContentText>
            <TextField
              className={classes.searchTextField}
              autoFocus
              fullWidth
              variant="outlined"
              placeholder="Search..."
              size="small"
              onChange={(event) => {
                setSearchFilter(event.target.value);
              }}
            />
            <div className={classes.listBoxContainer}>
              <List>
                {availableTechniciansFiltered.map(technician => (
                  <ListItem key={technician} className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <GreenCheckbox
                        id={technician}
                        key={technician}
                        color="primary"
                        checked={newlyAssignedTechnicians.includes(technician)}
                        inputProps={{ 'aria-labelledby': { technician } }}
                        onChange={event => toggleTechnicianAssignment(event.target.checked, technician)}
                      />
                    </ListItemIcon>
                    <ListItemText id={technician} primary={technician} className={classes.listItemText} />
                  </ListItem>
                ))}
              </List>
            </div>
          </>
        )}
      />
      <div className={classes.assignTechniciansButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.assignTechniciansButton}
          disabled={isAssignOrEditTechniciansButtonDisabled}
          onClick={openAssignTechniciansDialog}
          fullWidth
        >
          {assignOrEditTechniciansButtonText}
        </Button>

      </div>
    </Card>
  );
};

TechAssignment.propTypes = {
  appointmentId: string,
  jobId: string.isRequired,
  availableTechnicians: arrayOf(string),
  assignedTechnicians: arrayOf(string),
  jobStatus: string.isRequired,
};

TechAssignment.defaultProps = {
  appointmentId: '',
  availableTechnicians: [],
  assignedTechnicians: [],
};

export default TechAssignment;

import React, { Fragment, useState } from 'react';
import get from 'lodash/get';
import {
  string,
  object,
} from 'prop-types';
import startCase from 'lodash/startCase';
import CheckMark from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { Button, DialogContentText } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import homeDepotIcon from '../../../assets/images/home-depot-logo-sq.png';
import blindsComIcon from '../../../assets/images/blinds-com-logo.png';
import { addressType } from '../../../shared/types';
import Card from '../Card';
import styles from './styles';
import { SourceIcon } from '../../../shared/components';
import { JOB_STATUS } from '../../../apollo/mutations';
import { LOGIN_USER, JOB_STATUS_FINALIZED_IN_APP, JOB_SOURCE_INSTORE } from '../../../constants';
import LoadingDialog from '../../../shared/components/LoadingDialog';

const attributesConfig = [
  {
    label: 'Order Placed',
    renderAttribute: ({ order = {} }) => {
      const { date } = order;

      if (!date) return 'NA';

      return moment(date).format('MMM Do, YYYY');
    },
  },
  {
    label: 'THD PO',
    path: 'order.orderNumber',
  },
  {
    label: 'GCC PO',
    renderAttribute: ({ order = {} }) => {
      const { poNumbers = [] } = order;

      return poNumbers.map(poNumber => (
        <Typography key={poNumber} variant="body1">
          {poNumber}
        </Typography>
      ));
    },
  },
  {
    label: 'Store NO.',
    path: 'storeNumber',
  },
];

const getSiteIcon = (siteCode = '') => {
  const normalizedSiteCode = siteCode.toUpperCase();

  if (normalizedSiteCode === 'THD') return homeDepotIcon;

  if (normalizedSiteCode === 'BDC') return blindsComIcon;

  return null;
};

const renderSiteInfo = (siteCode = '', classes) => {
  const src = getSiteIcon(siteCode);

  return (
    <span style={{ marginRight: 16 }}>
      <img src={src} alt="site-source" className={classes.icon} />
    </span>
  );
};

const renderJobStatus = (status = '', classes) => {
  const isComplete = status.toLowerCase() === 'completed';

  if (!isComplete) return <Typography variant="body1">{startCase(status)}</Typography>;

  return (
    <div className={classes.completedStatusContainer}>
      <CheckMark className={classes.checkMark} />
      <Typography variant="body1" className={classes.completed}>Completed</Typography>
    </div>
  );
};

const renderAddress = (address = {}, classes) => (
  <div className={classes.attributeContainer}>
    <Typography className={classes.label}>Address</Typography>
    <Typography variant="body1">{address.line1}</Typography>
    <Typography variant="body1">{address.line2}</Typography>
    <Typography variant="body1">
      {address.city}, {address.state} {address.zipcode}
    </Typography>
  </div>
);

const renderAttributeContent = (path, renderAttribute, props, classes) => {
  if (renderAttribute) return renderAttribute(props, classes);

  const value = get(props, path);

  return <Typography variant="body1">{value}</Typography>;
};

const renderAttributes = (props, classes) => attributesConfig
  .map(({ label, path, renderAttribute }) => (
    <div key={label} className={classes.attributeContainer}>
      <Typography className={classes.label}>{label}</Typography>
      {renderAttributeContent(path, renderAttribute, props, classes)}
    </div>
  ));

const JobInformation = ({
  jobId, siteCode, status, address, source, classes, changeOrder, ...rest
}) => {
  const [updateJobStatusMutation, { error, loading, reset }] = useMutation(JOB_STATUS, {
    errorPolicy: 'none',
  });
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);

  const completeJob = () => {
    updateJobStatusMutation({
      variables: {
        setJobStatusInput: {
          jobId: jobId,
          status: 'completed',
          updatedBy: LOGIN_USER(),
        },
      },
    }).then((response) => {
      if (!response.errors) {
        setShowCompletionDialog(false);
      }
    });
  };

  const onCompleteJobClicked = () => {
    setShowCompletionDialog(true);
  };

  const closeCompleteJobDialog = () => {
    setShowCompletionDialog(false);
    reset();
  };

  return (
    <Card title="Job Information">
      <div className={classes.siteInfo}>
        {renderSiteInfo(siteCode, classes)} <SourceIcon source={source} showLabel />
      </div>
      <Fragment>
        <Typography className={classes.label}>Job Status</Typography>
        {renderJobStatus(status, classes)}
      </Fragment>
      {renderAddress(address, classes)}
      {renderAttributes(rest, classes)}

      {status.toLowerCase() === JOB_STATUS_FINALIZED_IN_APP && source.toLowerCase() === JOB_SOURCE_INSTORE && (
      <div className={classes.completeJobContainer}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onCompleteJobClicked}
        >
          Complete Job
        </Button>
      </div>
      )}

      {showCompletionDialog && (
      <LoadingDialog
        isLoading={loading}
        error={error}
        isOpen={showCompletionDialog}
        isDialogSubmitButtonDisabled={false}
        dialogTitleText="Complete Job"
        dialogSubmitButtonText="Complete Job"
        onErrorTryAgain={completeJob}
        onDialogClose={closeCompleteJobDialog}
        onDialogSubmit={completeJob}
        dialogContent={(
          <DialogContentText style={{ color: '#1F1F1F' }}>
            Have you reviewed all notes and payment details attached to this order? If so, continue below to complete the job.
          </DialogContentText>
        )}
      />
      )}

    </Card>
  );
};

JobInformation.propTypes = {
  jobId: string.isRequired,
  siteCode: string.isRequired,
  status: string.isRequired,
  address: addressType.isRequired,
  source: string.isRequired,
  classes: object.isRequired,
  changeOrder: object.isRequired,
};

export default withStyles(styles)(JobInformation);

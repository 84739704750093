import React from 'react';
import get from 'lodash/get';
import { jobType } from '../../types';
import SourceIcon from '../SourceIcon';

const SourceCell = ({ job }) => {
  const source = get(job, 'source');

  return (
    <SourceIcon source={source} />
  );
};

SourceCell.propTypes = {
  job: jobType.isRequired,
};

export default SourceCell;

import React, { useState } from 'react';
import {
  string,
  arrayOf,
  bool,
  object,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import UpArrow from '@material-ui/icons/KeyboardArrowUp';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';
import classnames from 'classnames';

import { itemType } from '../../../../shared/types';
import windowImage from '../../../../assets/images/window.png';
import WindowSummary from './WindowSummary';
import styles from './styles';

const renderShowWindowsButton = (numberOfWindows, showWindows, toggleShowWindows, classes) => (
  <div className={classes.windowDetailsContainer}>
    <div className={classes.windowIconContainer}>
      <img src={windowImage} className={classes.window} alt="number of windows" />
      <Typography className={classes.windowIconText}>{numberOfWindows}</Typography>
    </div>
    <div className={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
      <IconButton onClick={toggleShowWindows}>
        {showWindows ? <UpArrow /> : <DownArrow />}
      </IconButton>
    </div>
  </div>
);

const renderWindowSummaries = (showWindows, windows) => {
  if (!showWindows) return null;

  return windows.map((windowInfo, index) => (
    <WindowSummary key={windowInfo.id} isFirst={index === 0} {...windowInfo} />
  ));
};

const RoomSummary = ({
  label,
  name,
  children = [],
  isFirst,
  classes,
}) => {
  const [showWindows, setShowWindows] = useState(true);
  const toggleShowWindows = () => setShowWindows(!showWindows);

  const containerClasses = classnames(
    classes.container,
    {
      [classes.border]: !isFirst,
    },
  );

  const numberOfWindows = children.length;

  return (
    <div>
      <div className={containerClasses}>
        <div className={classes.circle}>
          <Typography className={classes.circleLabel}>{label}</Typography>
        </div>
        <div className={classes.nameContainer}>
          <Typography className={classes.name}>{name}</Typography>
        </div>
        {renderShowWindowsButton(numberOfWindows, showWindows, toggleShowWindows, classes)}
      </div>
      <Collapse in={showWindows}>
        {renderWindowSummaries(showWindows, children)}
      </Collapse>
    </div>
  );
};

RoomSummary.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  children: arrayOf(itemType),
  isFirst: bool.isRequired,
  classes: object.isRequired,
};

RoomSummary.defaultProps = {
  children: [],
};

export default withStyles(styles)(RoomSummary);

import React from 'react';
import get from 'lodash/get';
import { bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { phoneFormatter } from '../../utilities';
import { jobType } from '../../types';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.text.boldWeight,
  },
}));

const PrimaryPhoneNo = ({ job, isBold }) => {
  const classes = useStyles();
  const primaryNumber = get(job, 'customer.phones.0.number');

  if (!primaryNumber) return null;

  return (
    <div style={{ minWidth: '100px' }} className={isBold ? classes.bold : undefined}>{phoneFormatter(primaryNumber)}</div>
  );
};

PrimaryPhoneNo.propTypes = {
  job: jobType.isRequired,
  isBold: bool,
};

PrimaryPhoneNo.defaultProps = {
  isBold: false,
};

export default PrimaryPhoneNo;

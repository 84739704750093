/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import get from 'lodash/get';
import isString from 'lodash/isString';
import {
  arrayOf,
  object,
  bool,
  number,
  string,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from './Toolbar';
import Head from './Head';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'scroll',
    marginTop: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

function getRowCount(rowsPerPage, dataLength) {
  if (dataLength >= rowsPerPage) return rowsPerPage;

  return dataLength;
}

function desc(a, b, orderBy) {
  const bValue = get(b, orderBy);
  const aValue = get(a, orderBy);

  // handle capital letters
  const normalizedBValue = isString(bValue) ? bValue.toLowerCase() : bValue;
  const normalizedAValue = isString(aValue) ? aValue.toLowerCase() : aValue;

  if (normalizedBValue < normalizedAValue) return -1;
  if (normalizedBValue > normalizedAValue) return 1;

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function sortAndPaginateData(data, order, orderBy, page, rowsPerPage) {
  return stableSort(data, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );
}

function renderTableCells(item, tableConfig) {
  return tableConfig.map(({ id, renderValue, itemPath, cellProps }) => (
    <TableCell {...cellProps} key={id}>
      {renderValue ? renderValue(item) : get(item, itemPath)}
    </TableCell>
  ));
}

function renderTableRows(data, tableConfig, isSelected, handleClick, hasCheckboxes) {
  return data.map((item) => {
    const isItemSelected = isSelected(item.id);

    if (hasCheckboxes) {
      return (
        <TableRow
          hover
          onClick={event => handleClick(event, item.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={item.id}
          selected={isItemSelected}
        >
          <TableCell padding="checkbox" id="do-not-print">
            <Checkbox checked={isItemSelected} />
          </TableCell>
          {renderTableCells(item, tableConfig)}
        </TableRow>
      );
    }

    return (
      <TableRow
        tabIndex={-1}
        key={item.id}
      >
        {renderTableCells(item, tableConfig)}
      </TableRow>
    );
  });
}

function renderEmptyRows(rowsPerPage, dataLength, page) {
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

  if (emptyRows <= 0) return null;

  return (
    <TableRow style={{ height: 49 * emptyRows }}>
      <TableCell colSpan={6} />
    </TableRow>
  );
}

function renderTableBody(
  sortedAndPaginatedData,
  tableConfig,
  isSelected,
  handleClick,
  hasCheckboxes,
  rowsPerPage,
  data = [],
  page,
) {
  return (
    <TableBody>
      {renderTableRows(
        sortedAndPaginatedData,
        tableConfig,
        isSelected,
        handleClick,
        hasCheckboxes,
      )}
      {renderEmptyRows(rowsPerPage, data.length, page)}
    </TableBody>
  );
}

function renderPagination(
  pagination,
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
) {
  if (!pagination) return null;

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}

function EnhancedTable({
  data = [],
  tableConfig,
  classes,
  pagination,
  rowsShown = 5,
  title,
  actionsConfig = [],
  initialOrderKey = '',
  initialOrderDirection = 'asc',
  hasCheckboxes = false,
}) {
  const [order, setOrder] = React.useState(initialOrderDirection);
  const [orderBy, setOrderBy] = React.useState(initialOrderKey);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsShown);

  useEffect(() => setPage(0), [data]);

  const actionsConfigWithData = actionsConfig.map(configItem => ({
    ...configItem,
    onClick: () => configItem.onClick(selected, data), // feels dirty
  }));

  const sortedAndPaginatedData = sortAndPaginateData(
    data,
    order,
    orderBy,
    page,
    rowsPerPage,
  );

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    const isChecked = event.target.checked;
    const newSelecteds = isChecked ? sortedAndPaginatedData.map(item => item.id) : [];

    setSelected(newSelecteds);
  }

  function handleClick(event, id) {
    // refactor to use array logic with filter not this junk
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = event => setRowsPerPage(event.target.value);

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <Paper className={classes.root}>
      <Toolbar
        numSelected={selected.length}
        title={title}
        actionsConfig={actionsConfigWithData}
      />
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <Head
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={getRowCount(rowsPerPage, data.length)}
            tableConfig={tableConfig}
            hasCheckboxes={hasCheckboxes}
          />
          {renderTableBody(
            sortedAndPaginatedData,
            tableConfig,
            isSelected,
            handleClick,
            hasCheckboxes,
            rowsPerPage,
            data,
            page,
          )}
        </Table>
      </div>
      {renderPagination(
        pagination,
        data,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
      )}
    </Paper>
  );
}

EnhancedTable.propTypes = {
  data: arrayOf(object),
  tableConfig: arrayOf(object).isRequired,
  classes: object.isRequired,
  pagination: bool,
  rowsShown: number,
  title: string.isRequired,
  actionsConfig: arrayOf(object),
  initialOrderKey: string,
  initialOrderDirection: string,
  hasCheckboxes: bool,
};

EnhancedTable.defaultProps = {
  data: [],
  pagination: false,
  rowsShown: 5,
  actionsConfig: [],
  initialOrderKey: '',
  initialOrderDirection: 'asc',
  hasCheckboxes: false,
};

export default withStyles(styles)(EnhancedTable);

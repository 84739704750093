/* eslint-disable import/no-cycle */
import filter from 'lodash/filter';
import { isChangeOrderNote } from '.';

const getChangeOrderNoteId = (allNotes, jobSource) => {
  const changeOrderNotes = filter(allNotes, n => isChangeOrderNote(n.author, n.authorRole, jobSource));
  if (changeOrderNotes.length) {
    return changeOrderNotes[0].id;
  }
  return null;
};

export default getChangeOrderNoteId;

import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import capitalize from 'lodash/capitalize';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { buildItemsTree } from '../../../shared/utilities';
import Card from '../Card';
import RoomSummary from './RoomSummary';
import styles from './styles';
import JobAttributesList from './JobAttributesList';
import PdfLinks from './PdfLinks';
// import Payout from './Payout';

const renderWindowCategories = (windowCategories, classes) => windowCategories
  .map(category => (
    <Typography variant="body1" key={category} className={classes.category}>
      - {category}
    </Typography>
  ));

const renderWindowCategoriesSection = (jobAttributes, classes) => {
  const { windowCategories = [] } = jobAttributes;

  if (!windowCategories || !windowCategories.length) return null;

  return (
    <div className={classes.categoryContainer}>
      <Typography variant="body1" className={classes.categoryPreface}>
        Customer is interested in:
      </Typography>
      {renderWindowCategories(windowCategories, classes)}
    </div>
  );
};

const renderRoomAndWindows = (items) => {
  const itemsTree = buildItemsTree(items);
  const rooms = Object.values(itemsTree).filter(item => item.type === 'room');
  const sortedRooms = sortBy(rooms, item => item.label);

  return sortedRooms.map((room, index) => <RoomSummary key={room.id} {...room} isFirst={index === 0} />);
};

const renderRoomAndWindowsSection = (items, classes) => {
  if (!items.length) return null;

  return (
    <div className={classes.roomAndWindowsContainer}>
      <Typography variant="h6" className={classes.boldFonts}>
        Rooms &amp; Windows
      </Typography>
      <div>{renderRoomAndWindows(items)}</div>
    </div>
  );
};

const ServiceInformation = ({
  job,
  classes,
}) => {
  const serviceTypeTitle = `${capitalize(job.serviceType)} Information`;

  return (
    <Card title={serviceTypeTitle}>
      {/* <Payout cost={cost} /> */}
      <Typography variant="h6" className={classes.boldFonts}>Window Treatments</Typography>
      <div className={classes.row}>
        <JobAttributesList attributes={job.aggregatedAttributes} />
      </div>
      <div className={classes.row}>
        {renderWindowCategoriesSection(job.aggregatedAttributes, classes)}
      </div>
      {renderRoomAndWindowsSection(job.items, classes)}
      <PdfLinks job={job} />
    </Card>
  );
};

ServiceInformation.propTypes = {
  job: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ServiceInformation);

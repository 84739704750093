import React, { useState } from 'react';
import {
  string,
  object,
} from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import classNames from 'classnames';
import { ServiceTypeIcon, GreenCheckbox } from '../../../shared/components';

import styles from './styles';
import { OLD_VENDOR_ADMIN_URL } from '../../../constants';

const OrderHeader = ({ serviceType, orderNumber, jobId, classes }) => {
  const [checked, setChecked] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const getPrintOrderDetailsUrl = () => {
    const link = document.createElement('a');
    link.download = 'download.pdf';
    link.href = `${OLD_VENDOR_ADMIN_URL}/api/order/print/${jobId}?FeatureToggle.GraphQL=true&includePayouts=${checked}`;
    link.target = '_blank';
    link.click();
    setIsDialogOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.container}>
      <div className={classes.orderAndIconContainer}>
        <ServiceTypeIcon serviceType={serviceType} size="large" />
        <Typography
          variant="h4"
          classes={{ root: classes.headerText }}
        >
          {orderNumber}
        </Typography>
      </div>
      <Dialog open={isDialogOpen}>
        <DialogTitle className={classes.headerText}>Download PDF</DialogTitle>
        <DialogContent>
          <DialogContentText>Download a print friendly PDF of this job.</DialogContentText>
          <div className={classes.pdfDialogCheckboxText}>
            <GreenCheckbox checked={checked} onChange={handleChange} />
            <div>Include Payout Details</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => setIsDialogOpen(false)}
            className={classNames(classes.pdfDialogButtonText, classes.pdfDialogButtonBorder)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={getPrintOrderDetailsUrl}
            className={classes.pdfDialogButtonText}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        className={classes.downloadPDFText}
        onClick={() => setIsDialogOpen(true)}
        variant="text"
        endIcon={<GetAppIcon />}
      >
        Download PDF
      </Button>
    </div>
  );
};

OrderHeader.propTypes = {
  serviceType: string.isRequired,
  orderNumber: string.isRequired,
  jobId: string.isRequired,
  classes: object.isRequired,
};

export default withStyles(styles)(OrderHeader);
